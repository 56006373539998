import React from "react"
import cx from "classnames"
import InstallCEButton from "../InstallCEButton/InstallCEButton"
import { newlines } from "../../utils"
import styles from "../../styles/homepage.module.scss"

import Hero from "../Hero/Hero"
import FancySection from "../FancySection/FancySection"
import ArrowRight from "../../assets/arrow-right.svg"
import Glossier from "../../assets/customers/glossier.svg"
import Doordash from "../../assets/customers/doordash.svg"
import Intercom from "../../assets/customers/intercom.svg"
import Reddit from "../../assets/customers/reddit.svg"
import Turo from "../../assets/customers/turo.svg"

const Logos = {
  glossier: <Glossier />,
  doordash: <Doordash />,
  intercom: <Intercom />,
  reddit: <Reddit />,
  turo: <Turo />,
}

const OutreachHomepage = ({
  subtitle,
  title,
  img,
  featuredQuote,
  logos,
  heroBlocks,
  quoteHero,
  featuresTitle,
  features,
}) => (
  <>
    <Hero classNames={["mainHero", "textLeft", styles.hero]}>
      <div className={cx("text")}>
        <h1 className="display-1">{newlines(title)}</h1>
        {subtitle && <h4>{subtitle}</h4>}
        <InstallCEButton className="btn-lg" />
      </div>
      <div className={cx("image")}>
        <img src={img} alt="A new standard in recruiting software." />
      </div>
    </Hero>
    <section className={cx("customers", "pb-0")}>
      <div className={cx("container", "p-0")} style={{ textAlign: "center" }}>
        <div className={cx("inline-container", "jc-center", "mb-1")}>
          {logos.map(({ logo }) => (
            <div key={`c${logo}`}>{Logos[logo]}</div>
          ))}
        </div>
        <h1 className="fw-normal lato mb-1">
          &ldquo;{featuredQuote.quote}&rdquo;
        </h1>
        <p>{featuredQuote.attribution}</p>
      </div>
    </section>

    {heroBlocks.map(hero => (
      <div
        key={hero.className}
        className={cx(styles.homeHero, styles[hero.className])}
      >
        <div className={cx(styles.relativeWrap, "container")}>
          <img
            className={cx(styles.mobileFeature)}
            alt="hero img"
            src={hero.img}
          />
          <div className={cx(styles.heroContent)}>
            <div className={cx(styles.contentContainer)}>
              <h2 className="display-2">{newlines(hero.title)}</h2>
              {hero.subtitle && <p className="big">{hero.subtitle}</p>}
              <p>
                <a href="https://gem.com">
                  {hero.cta}{" "}
                  <ArrowRight className="svg-red" style={{ height: "12px" }} />
                </a>
              </p>
            </div>
          </div>
          <div
            className={cx(styles.heroFeature)}
            style={{ backgroundImage: `url("${hero.img}")` }}
          />
          <div
            className={cx(styles.heroAccent)}
            style={{
              backgroundImage: `url("/images/accents/${hero.accent}.svg")`,
            }}
          />
          <img
            className={cx(styles.mobileFeature2)}
            alt="hero img"
            src={hero.img}
          />
        </div>
      </div>
    ))}
    <FancySection {...quoteHero} />
    <section className={cx("features")}>
      <div className={cx("container")}>
        <h2 style={{ marginBottom: "2.4rem" }}>
          <img
            className="mb-1 mr-2"
            src="/images/accents/small-wave.svg"
            alt=""
          />{" "}
          {featuresTitle}{" "}
        </h2>
        <div className={cx("inline-container", "ic-4", "ai-top")}>
          {features.map(feature => (
            <div key={feature.title}>
              <img
                src={feature.img}
                alt={feature.title}
                className={cx("mb-2")}
              />
              <h4 className="mb-2">{feature.title}</h4>
              <p className="mb-2">{feature.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
)

export default OutreachHomepage
