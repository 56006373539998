import React, { useState } from 'react';
import cx from 'classnames';
import { Dialog, Card, Typography } from '@material-ui/core';

import styles from './InfoCard.module.scss';

const InfoCard= ({
  icon,
  title,
  description,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card
        className={cx(
          styles.card,
          !description ? styles.cardWithoutDescription : '',
          !icon ? styles.cardWithoutIcon : '',
        )}
        key={title}
        onClick={()=> {
          setOpen(true);
        }}
      >
        {icon && (
        <Typography className={styles.cardIcon} variant="h3">
          {icon}
        </Typography>
        )}
        {title && (
        <Typography className={styles.cardTitle} variant="h5">
          {title}
        </Typography>
        )}
        {description && (
        <Typography className={styles.cardDescription} variant="subtitle1">
          {description}
        </Typography>
        )}
      </Card>
      <Dialog
        className={styles.cardDialogBackground}
        onClose={()=> setOpen(false)}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth
      >
        <div className={styles.cardDialog}>
          {icon && (
          <Typography className={styles.cardIcon} variant="h2">
            {icon}
          </Typography>
          )}
          {title && (
          <Typography className={styles.cardTitle} variant="h4">
            {title}
          </Typography>
          )}
          {description && (
          <Typography className={styles.cardDescription} variant="subtitle1">
            {description}
          </Typography>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default InfoCard;
