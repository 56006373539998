import React from 'react';
import DetailedFeedback, { SubmitDetailedFeedbackCallback } from './DetailedFeedback/DetailedFeedback';

const WhatWentWell= ({ onSubmitDetailedFeedback }) => (
  <DetailedFeedback
    onSubmitDetailedFeedback={onSubmitDetailedFeedback}
    title="What went well?"
  />
);
export default WhatWentWell;
