import React  from 'react';
import cx from 'classnames';

import { newlines } from '../../utils';
import styles from './Callout.module.scss';

const Callout = ({ text, accent, title, quote, color, highlightColor, focused }) => {
  return (
    <div 
      className={cx(styles.callout, { [styles.focused]: !!focused })}
      style={{ backgroundImage: `url("/images/accents/${accent}.svg")`}}
    >
      <div>
        <h1 className={cx("display-1", styles.title)}>{ newlines(title || '') }</h1>
        <h2 className={cx("big", styles.calloutText)}>{text}</h2>
        {quote && <Quote quote={quote} color={color} highlightColor={highlightColor} />}
      </div>
    </div>
);
};

export const Quote =({ quote, color, highlightColor }) => {
  if (!quote || !quote.text) {
    return null;
  }
  // This script breaks the text into lines where there are never more than 20 characters on the 
  // line. This allows us to apply the CSS highlighter effect properly
  const brokenIntoLines = quote.text
    .trim()
    .split(' ')
    .reduce((prev, word) => {
      if (prev.length === 0) {
        return [word];
      }

      if (prev[prev.length - 1].length > 20) {
        return [...prev, word];
      }
      const last = prev.pop();
      return [...prev, `${last} ${word}`];
    }, []);

  return (
    <div className={styles.quote}>
      <div 
        className={styles.startingQuoteMark}
        style={{color }}
      ><h1>“</h1>
      </div>
      {brokenIntoLines.map((line, i) => (
        <>
          <h2 className={styles.line}>
            {line}
            <div 
              className={styles.highlight}
              style={{ backgroundColor:  highlightColor}}
            />
          </h2>
          {/* don't need a linebreak at the last one to make it easier to position the closing 
          quote */}
          { i !== brokenIntoLines.length - 1 && <br />}
        </>
      ))}
      <div
        className={styles.endingQuoteMark}
        style={{color }}
      ><h1>“</h1>
      </div>
      <h3 className={styles.attribution}>- {quote.author}</h3>
    </div>
);
};

export default Callout;
