import React, {
  useState, useEffect,
} from 'react';
import {
  Typography,
} from '@material-ui/core';
import EmojiSelector from './EmojiSelector';
import options from './options';

import feedbackStyles from '../../feedback.module.scss';

const EmojiStep =({ setDescriptionText, onOptionSelected }) => {
  const [optionHovered, setOptionHovered] = useState(null);
  useEffect(() => {
    setDescriptionText(optionHovered ? optionHovered.description : '');
  }, [optionHovered, setDescriptionText]);
  return (
    <>
      <Typography className={feedbackStyles.header} variant="h4">
      How was your interview?
      </Typography>
      <EmojiSelector
        options={options}
        onHoverOption={(option)=> {
          setOptionHovered(option);
        }}
        onSelectOption={(option)=> {
          onOptionSelected(option);
        }}
      />
    </>
  );
};

export default EmojiStep;
