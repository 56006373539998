import React from 'react';
import {
  Paper, Typography,
} from '@material-ui/core';
import styles from './Greeting.module.scss';

const Greeting = ({
  firstName = 'Candice',
  role='Software Engineer'
}) => {
  return (
    <Paper>
      <div className={styles.headerGreetingContainer}>
        <Typography
          className={styles.greeting}
          variant="h3"
        >
      Hi {firstName} 👋
        </Typography>
        <Typography
          className={styles.role}
          variant="h4"
        >
          {role}
        </Typography>
      </div>

    </Paper>
)
};

export default Greeting;


