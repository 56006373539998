import React from 'react';
import cx from 'classnames';
import { Chip } from '@material-ui/core';

import styles from './CategoryChip.module.scss';

const CategoryChip= ({ category: { description, emoji }, isSelected, setSelected }) => {
  return (
    <Chip
      className={cx(styles.chip, { [styles.selected]: !!isSelected })}
      variant="outlined"
      onClick={()=> {
        setSelected(!isSelected);
      }}
      label={`${emoji} ${description}`}
    />
  );
};
export default CategoryChip;
