import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { lifecycle } from 'recompose';
import PropTypes from 'prop-types';
import cx from 'classnames';
import IntercomChat, { IntercomAPI } from 'react-intercom';
import Layout from '../components/Layout';
import settings from '../settings';
import GuidesHomepage from '../components/GuidesHomepage';
import OutreachHomepage from '../components/OutreachHomepage';

export const HomepageTemplate = ({
  title: resourceTitle,
  subtitle: resourceSubtitle,
  img: resourceImg,
  guideTitle,
  guideSubtitle,
  guideImg,
  modules = [],
  logos = [],
  featuredQuote,
  heroBlocks = [],
  quoteHero,
  featuresTitle,
  features,
  location,
}) => {
  const [forceScrolling, setScrolling] = useState(false);
  const title = settings.IsInterviewGuide ? guideTitle : resourceTitle;
  const subtitle = settings.IsInterviewGuide ? <div dangerouslySetInnerHTML={{ __html:guideSubtitle }} /> : resourceSubtitle;
  const img = settings.IsInterviewGuide ? guideImg : resourceImg;
  return (
    <Layout
      location={location}
      excludeOffsetWhitespace={settings.IsInterviewGuide}
      forceScrolling={forceScrolling}
      showFooter={!settings.IsInterviewGuide}
    >
      <div className={cx('homepage')}>
        {settings.IsInterviewGuide ? (
          <GuidesHomepage
            modules={modules}
            subtitle={subtitle}
            title={title}
            setScrolling={setScrolling}
          />
) : (
  <OutreachHomepage
    subtitle={subtitle}
    title={title}
    img={img}
    featuredQuote={featuredQuote}
    logos={logos}
    heroBlocks={heroBlocks}
    quoteHero={quoteHero}
    featuresTitle={featuresTitle}
    features={features}
  />
)}
      </div>
      {!settings.IsInterviewGuide && (
      <script type="text/javascript">{`
          piAId = '824443';
          piCId = '1651';
          piHostname = 'pi.pardot.com';

          (function() {
            function async_load(){
              var s = document.createElement('script'); s.type = 'text/javascript';
              s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
              var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            }
            if(window.attachEvent) { window.attachEvent('onload', async_load); }
            else { window.addEventListener('load', async_load, false); }
          })();
        `}
      </script>
)}
    </Layout>
  )
};

HomepageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

HomepageTemplate.defaultProps = {
  subtitle: null,
};

const Homepage = lifecycle({
  componentDidMount() {
    IntercomAPI('boot');
  },
  })(({location}) => (
    <StaticQuery 
      query={graphql`
        query HomepageQuery {
          markdownRemark(frontmatter: { name: { eq: "homepage" } }) {
            frontmatter {
              title
              subtitle
              logos {
                logo
              }
              img
              guideTitle
              guideSubtitle
              guideImg
              modules {
                accent
                callout
                color
                calloutTitle
                moduleType
                quote {
                  text
                  author
                }
              }
              featuredQuote {
                quote
                attribution
              }
              heroBlocks {
                title
                subtitle
                className
                accent
                img
                cta
              }
              quoteHero {
                logo
                quote
                quotedName
                quotedRole
                quotedImg
                img
                flip
              }
              featuresTitle
              features {
                title
                img
                text
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <HomepageTemplate
            location={location}
            {...data.markdownRemark.frontmatter}
          />
          <IntercomChat appID="rg0xc7fz" />
        </>
      )}
    />
  ));

Homepage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Homepage;
