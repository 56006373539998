import React  from 'react';
import { ThemeProvider } from '@material-ui/core';
import cx from 'classnames';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import  { acmeTheme } from '../../styles/config';
import {
  StageTracker,
  Greeting,
  Time,
  Location,
  Schedule,
  Logistics,
  Feedback, 
  Media,
} from './Modules';

import styles from './HeroModule.module.scss';


momentDurationFormatSetup(moment);

const Modules = {
  stageTracker: StageTracker,
  greeting: Greeting,
  calendar: Time,
  location: Location,
  schedule: Schedule,
  logistics: Logistics,
  feedback: Feedback,
  photo: Media,
};


const HeroModule = ({ module, focused, domRef, onUnfocusedClick, className }) => {
  const ModuleComponent = Modules[module.moduleType];
  return ModuleComponent && (
    <div
      ref={domRef}
      key={module.moduleType}
      className={cx(styles.moduleHero, className, { [styles.focused]: !!focused })}
    >
      {!focused && (
      <div
        role="button"
        className={styles.clickableSheer}
        onClick={(event) => {
        if (!focused) {
          onUnfocusedClick();
          event.stopPropagation();
        }
      }}
      >{" "}
      </div>
)}
      <ThemeProvider theme={acmeTheme}>
        <ModuleComponent />
      </ThemeProvider>
    </div>
) ;
};

export default HeroModule;
