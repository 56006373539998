export { default as EmojiStep } from './EmojiStep/EmojiStep';
export { default as WhatWentWellStep } from './WhatWentWellStep';
export { default as WhatCouldBeBetterStep } from './WhatCouldBeBetterStep';
export { default as ThankYouStep } from './ThankYouStep';

const FeedbackStepEnum = {
  EmojiStep: 'emoji',
  WhatWentWellStep: 'www',
  WhatCouldBeBetterStep: 'wcbb',
  ThankYouStep: 'ty',
}

export { FeedbackStepEnum };

