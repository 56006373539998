import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import {
  Paper, Typography,
} from '@material-ui/core';

import styles from './ConferenceCard.module.scss';
import ZoomLogo from '../../../../assets/customers/zoom.svg';

const ConferenceCard = ({ link, text }) => {
  const zoom = link && text.includes('zoom.us');
  const icon = (zoom ? <ZoomLogo style={{ verticaAlign: 'middle', display: 'flex'}} /> : '💻');
  const textToUse = zoom ? "Join the Zoom meeting" : text;
  return (
    <Paper
      className={styles.conferenceCard}
      underline="none"
    >
      <Typography
        variant="h3"
        className={styles.emoji}
      >
        <span
          role="img"
          aria-label="Laptop Image"
        >
          {link ? icon  : '☎️'}
        </span>
      </Typography>
      <Typography
        variant="body1"
        className={styles.text}
      >
        {textToUse}
      </Typography>
      {link && (
      <FontAwesomeIcon
        className={styles.icon}
        icon={faChevronRight}
        size="1x"
      />
      )}
    </Paper>
);
};

export default ConferenceCard;
