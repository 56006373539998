import React from 'react';
import DetailedFeedback, { SubmitDetailedFeedbackCallback } from './DetailedFeedback/DetailedFeedback';

const WhatCouldBeBetterStep= ({ onSubmitDetailedFeedback }) => (
  <DetailedFeedback
    onSubmitDetailedFeedback={onSubmitDetailedFeedback}
    title="What could be improved?"
  />
);

export default WhatCouldBeBetterStep;
