import React, { useState } from 'react';
import {
  Paper, Typography,
  Avatar, Dialog,
  ExpansionPanel, ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import DescriptionDivider from './DescriptionDivider/DescriptionDivider';
import ConferenceCard from './ConferenceCard/ConferenceCard';

import variables from '../../../styles/modules/_variables.scss';
import styles from './Schedule.module.scss';

const interviewers = [{
  id: 0,
  imageUrl: '/images/company/team/troy.jpg',
  firstName: 'Troy',
  lastName: 'Sultan',
},{
  id: 2,
  imageUrl: '/images/company/team/austin.jpg',
  firstName: 'Austin',
  lastName: 'Cooley',
}, {
  id: 3,
  imageUrl: '/images/company/team/marcus.jpg',
  firstName: 'Marcus',
  lastName: 'Lowe',
}, {
  id: 4,
  imageUrl: '/images/company/team/taylor.png',
  firstName: 'Taylor',
  lastName: 'Wagner',
}
];
const tomorrow = moment().add(1, 'day').startOf('day').add(9, 'hours');

const Schedule = () => {
  const showMoreDetail = typeof(window) === 'undefined' || window.innerWidth > Number(variables.md.split('px')[0]);
  const events = [
    {
      startTime: tomorrow.toDate(),
      endTime: tomorrow.add(45, 'minutes').toDate(),
      isBreak: false,
      title: 'Cultural Values',
      description: 'Culture',
      eventInterviewers: [interviewers[0]],
    }, {
      startTime: tomorrow.toDate(),
      endTime: tomorrow.add(45, 'minutes').toDate(),
      isBreak: false,
      title: 'Algorithms',
      description: 'Welcome description',
      eventInterviewers: [interviewers[1]],
    }, {
      startTime: tomorrow.toDate(),
      endTime: tomorrow.add(5, 'minutes').toDate(),
      isBreak: true,
    }, {
      startTime: tomorrow.toDate(),
      endTime: tomorrow.add(100, 'minutes').toDate(),
      isBreak: false,
      title: 'Product Engineering',
      description: 'Welcome description',
      ...showMoreDetail ? {
      techScreenLink: 'https://coderpad.io',
      } : {},
      eventInterviewers: [interviewers[2]],
    }, ...showMoreDetail ? [{
      startTime: tomorrow.toDate(),
      endTime: tomorrow.add(70, 'minutes').toDate(),
      isBreak: false,
      title: 'Team Lunch',
      description: '',
      eventInterviewers: interviewers,
    }] : [],
  ];
  return (
    <Paper className={styles.scheduleContainer}>
      <Typography variant="h4">
        Schedule
      </Typography>
      <Typography variant="h6">
        {moment(tomorrow).format('ddd MMM Do')}
      </Typography>
      {events.map((eventOrBreak) => {
        if (eventOrBreak.isBreak) {
          const {
            startTime,
            endTime,
          } = eventOrBreak;
          const lengthMinutes = moment(endTime).diff(startTime, 'minutes');
          const startTimeText = moment(startTime).format('h:mm a');
          const breakLengthText = moment.duration(lengthMinutes, 'minutes').format('h [hr] m [min]', { usePlural: false, trim: 'all' });
          return (
            <div className={styles.eventBreak}>
              <DescriptionDivider text={(
                <span>
                  <strong>{startTimeText}</strong>&nbsp;&nbsp;{breakLengthText}{' '}break
                </span>
              )}
              />
            </div>
          );
        }

        const {
          id,
          startTime,
          endTime,
          title,
          description,
          techScreenLink,
          eventInterviewers,
        } = eventOrBreak;
        return (
          <Event
            key={id}
            startTime={startTime}
            endTime={endTime}
            title={title}
            description={description || ''}
            techScreenLink={techScreenLink || ''}
            eventInterviewers={eventInterviewers}
          />
        );
      })}
    </Paper>
  );
};


const Event = ({
  title,
  startTime,
  endTime,
  techScreenLink,
  eventInterviewers,
  description = '',
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const startTimeText = moment(startTime).format('h:mm a');
  const endTimeText = moment(endTime).format('h:mm a');

  return (
    <>
      <div
        tabIndex={0}
        role="button"
        className={styles.eventContainer}
        onClick={() => {
          setOpenDialog(true);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpenDialog(true);
          }
        }}
      >
        <div className={styles.timeAndInterviwers}>
          <div className={styles.timeAndTitle}>
            <Typography className={styles.time} variant="subtitle1">
              {startTimeText}
            </Typography>
            <div className={styles.titleAndIcon}>
              <Typography variant="body1">
                {title}
              </Typography>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faChevronRight}
                size="xs"
              />
            </div>
          </div>
          
          <div className={styles.interviewers}>
            {eventInterviewers.map(({
                id,
                imageUrl,
                firstName,
                lastName,
              }) => (
                <Avatar
                  key={id}
                  className={styles.interviewerAvatar}
                  src={imageUrl || undefined}
                  alt={`${firstName} ${lastName} Thumbnail`}
                >
                  {`${firstName[0]}${lastName[0]}`}
                </Avatar>
              ))}
          </div>
        </div>
        {
          techScreenLink && (
            <div style={{ marginTop: '20px' }}>
              <ConferenceCard
                link={techScreenLink}
                text="CoderPad"
              />
            </div>
          )
        }
      </div>
      <Dialog
        open={openDialog}
        onClose={()=> setOpenDialog(false)}
        aria-labelledby={styles.title}
        aria-describedby={styles.description}
        fullWidth
      >
        <div className={styles.dialogContainer}>
          <Typography
            className={styles.title}
            variant="h3"
          >
            {title}
          </Typography>
          <Typography variant="body1" className={styles.time}>
            {`${startTimeText} - ${endTimeText}`}
          </Typography>
          <Typography
            className={styles.description}
            variant="body1"
          >
            {description}
          </Typography>
          <div className={styles.interviewersContainer}>
            <Typography variant="h4">
              Interviewers
            </Typography>
            {eventInterviewers.map(({
              id,
              imageUrl,
              firstName,
              lastName,
              linkedInUrl,
              title: interviewerTitle,
              biography,
            }) => (
              <div
                key={id}
                className={styles.expansionHeader}
                style={{
                  boxShadow: 'none',
                  borderTop: 'solid 1px #f1f1f1',
                  border: 'solid 1px #f1f1f1',
                  margin: '5px 0px',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                <Avatar
                  key={id}
                  className={styles.interviewerAvatar}
                  src={imageUrl || undefined}
                  alt={`${firstName} ${lastName} Thumbnail`}
                >
                  {`${firstName[0]}${lastName[0]}`}
                </Avatar>
                <Typography className={styles.name} variant="h6">{`${firstName} ${lastName}`}</Typography>
                <div className={styles.detailsCont}>
                  <div className={styles.title}>
                    <Typography variant="h6">
                      {interviewerTitle}
                    </Typography>
                    <IconButton
                      className={styles.icon}
                      href={linkedInUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                      />
                    </IconButton>
                  </div>
                  <Typography variant="body1">
                    {biography}
                  </Typography>
                </div>
              </div>
            ))}
          </div>

        </div>
      </Dialog>
    </>
  );
};


export default Schedule;


