import React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import styles from './Time.module.scss';

const getScheduleGoogleCalendarLink= ({
  location,
  startTime,
  endTime,
  guideUrl,
  companyName,
  eventTitle,
  eventDescription,
}) => {
  const params = {
    action: 'TEMPLATE',
    text: eventTitle || `Interview at ${companyName}`,
    details: eventDescription || `Interview with ${companyName}.\n\n${guideUrl}`,
    location: location || '',
    dates: `${moment(startTime).utc().format('YYYYMMDDTHHmmss')}Z/${moment(endTime).utc().format('YYYYMMDDTHHmmss')}Z`,
  };
  const queryString = Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  return `https://www.google.com/calendar/render?${queryString}`;

}

const Time = ({
  startTime = moment().toDate(), 
  endTime = moment().add(1, 'hour').toDate(),
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  remoteInstructions = 'Join us on this quest to create better candidate experiences!',
  guideUrl = 'https://www.interviewguide.co',
  companyName = 'ACME',
}) => {
  const url = getScheduleGoogleCalendarLink({
    location: remoteInstructions, startTime, endTime, guideUrl, companyName,
  });
  const interviewDate = moment(startTime).format('ddd MMM Do');
  const startTimeText = moment.tz(startTime, timezone).format('h:mm a');
  const endTimeText = moment.tz(endTime, timezone).format('h:mm a');
  const timeZoneText = moment.tz(endTime, timezone).format('z');
  return (
    <Paper className={styles.timeContainer}>
      <Typography variant="h4">
        Time
      </Typography>
      <div className={styles.times}>
        <div className={cx(styles.date)}>
          <div className={cx(styles.dateTime)}>
            <Typography variant="body1">
              {interviewDate}
              <br />
              {startTimeText}
              {' '}
    -
              {endTimeText}
              {' '}
    (
              {timeZoneText}
    )
            </Typography>
          </div>
          <a
            color="inherit"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            className={cx(styles.addToCalendar)}
          >
    Add to calendar
          </a>
        </div>
      </div>
    </Paper>
  );
};

export default Time;
