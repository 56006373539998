import React from 'react';
import cx from 'classnames';
import {
  Typography,
} from '@material-ui/core';

import feedbackStyles from '../feedback.module.scss';
import styles from './thank-you-step.module.scss';

const ThankYou= () => {
  return (
    <>
      <div className={styles.header}>
        <Typography
          className={cx(feedbackStyles.header, styles.stepTitle)}
          variant="h4"
        >
          Thank you for your feedback! 
          {' '}
          <span role="img" aria-label="thanks">🙏</span>
        </Typography>
      </div>
      <Typography variant="body1">
        Sharing your experience helps improve the interview process for
        future candidates like you.
      </Typography>
    </>
);
};

export default ThankYou;
