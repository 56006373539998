import React, {
  useState,
} from 'react';
import {
  Card, Typography,
} from '@material-ui/core';
import {
  EmojiStep, WhatWentWellStep, WhatCouldBeBetterStep, ThankYouStep, FeedbackStepEnum,
} from './steps';
import DescriptionDivider from '../DescriptionDivider/DescriptionDivider';
import styles from './feedback.module.scss';

const Controller = ({
}) => {
  const [currentStep, setCurrentStep] = useState(FeedbackStepEnum.EmojiStep);
  const [descriptionText, setDescriptionText] = useState(null);

  const finish = () => {
    setCurrentStep(FeedbackStepEnum.ThankYouStep);
  };


  return (
    <Card className={styles.container}>
      {
        (() => {
          switch (currentStep) {
            case FeedbackStepEnum.EmojiStep:
              return (
                <EmojiStep
                  setDescriptionText={setDescriptionText}
                  onOptionSelected={(option)=> {
                    setDescriptionText(null);
                    setCurrentStep(option.value >= 2 ? FeedbackStepEnum.WhatWentWellStep : FeedbackStepEnum.WhatCouldBeBetterStep);
                  }}
                />
              );
            case FeedbackStepEnum.WhatWentWellStep:
              return (
                <WhatWentWellStep onSubmitDetailedFeedback={(freeform, categories)=> {
                  finish(freeform, categories);
                }}
                />
              );
            case FeedbackStepEnum.WhatCouldBeBetterStep:
              return (
                <WhatCouldBeBetterStep onSubmitDetailedFeedback={(freeform, categories)=> {
                  finish(freeform, categories);
                }}
                />
              );
            case FeedbackStepEnum.ThankYouStep:
              return <ThankYouStep />;
            default:
              return null;
          }
        })()
      }
      {currentStep !== FeedbackStepEnum.ThankYouStep && (
        <>
          <DescriptionDivider text={descriptionText} />
          <Typography variant="subtitle2" className={styles.anonymousText}>
            Don’t worry - your feedback is anonymous and safe with us.
            It is aggregated to protect your identity and won’t be shared
            until after the hiring decision.
          </Typography>
        </>
      )}
    </Card>
  );
};

export default Controller;
