import React, { useState, useEffect, useRef } from "react"
import cx from "classnames"
import ReactFullpage from "@fullpage/react-fullpage"
import { Paper, Typography } from "@material-ui/core"
import _ from "lodash"

import { newlines } from "../../utils"
import Hero from "../Hero/Hero"
import Footer from "../Footer/Footer"
import HeroModule from "../HeroModule/HeroModule"
import styles from "./guides-homepage.module.scss"
import Callout from "../HeroModule/Callout"

import gitlabLogo from "../../assets/guide-customers/gitlab.png"
import glossierLogo from "../../assets/guide-customers/glossier.png"
import homeadvisorLogo from "../../assets/guide-customers/homeadvisor.png"
import mongodbLogo from "../../assets/guide-customers/mongodb.png"
import netlifyLogo from "../../assets/guide-customers/netlify.png"
import omadaLogo from "../../assets/guide-customers/omada.png"
import onemedicalLogo from "../../assets/guide-customers/onemedical.png"
import redditLogo from "../../assets/guide-customers/reddit.png"
import useScrollPosition from "./useScrollPosition"

const colors = {
  electricRed: "#ff2e57",
  electricRed100: "#FFEAEE",
  electricRed200: "#FFABBC",
  electricRed400: "#FF5879",
  royalPurple: "#7348ff",
  royalPurple100: "#F1EDFF",
  royalPurple200: "#C7B6FF",
  royalPurple400: "#8F6DFF",
  floridaOrange: "#ff7227",
  floridaOrange100: "#FFF1E9",
  floridaOrange200: "#FFC7A9",
  floridaOrange400: "#FF8E52",
  crystalBlue: "#03EAFF",
  crystalBlue100: "#EBFCFF",
  crystalBlue200: "#AFF4FF",
  crystalBlue400: "#60E9FF",
}

// all color groupings are defined by a single key which is a camel case version of the color's
// name (e.g. electricRed). Every color has 3 different versions that represent that colors's
// various usage throughout the product
const getColors = colorName => {
  const color = colors[`${colorName}400`]
  const bgColor = colors[`${colorName}200`]
  const highlightColor = colors[`${colorName}100`]
  return { color, bgColor, highlightColor }
}

const ValueProp = ({ title, subtitle, className, logos = [] }) => (
  <div className={cx(styles.hero, className)}>
    <Hero classNames={["mainHero", "textCenter"]}>
      <div className={cx("text", styles.valueprop)}>
        <h1 className="display-1">{newlines(title)}</h1>
        {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
        <a
          href="https://candidate.guide/walkthrough/"
          alt="Get Started"
          className={cx(styles.tryCta)}
        >
          Learn More{" "}
          <span role="img" aria-label="go">
            👉
          </span>
        </a>
        <div className={styles.logoContainer} style={{ marginTop: 40 }}>
          <div className={styles.logo}>
            <img src={gitlabLogo} alt="Gitlab" />
          </div>
          <div className={styles.logo}>
            <img src={mongodbLogo} alt="Mongodb" />
          </div>
          <div className={styles.logo}>
            <img src={redditLogo} alt="Reddit" />
          </div>
          <div className={styles.logo}>
            <img src={netlifyLogo} alt="Netlify" />
          </div>
          <div className={styles.logo}>
            <img src={glossierLogo} alt="Glossier" />
          </div>
          {/* <div className={styles.logo}>
            <img src={homeadvisorLogo} alt="Homeadvisor" />
          </div>
          <div className={styles.logo}>
            <img src={omadaLogo} alt="Omada" />
          </div>
          <div className={styles.logo}>
            <img src={onemedicalLogo} alt="Onemedical" />
          </div> */}
        </div>
      </div>
    </Hero>
  </div>
)

const GuidesHomepage = ({
  title,
  subtitle,
  modules,
  page,
  setPage,
  setScrolling,
  logos,
}) => {
  // show the scroll bar after the first module
  useEffect(() => {
    setScrolling(page > 0)
  }, [page])
  // create refs for each module
  const refs = modules.map(() => useRef(null))
  const calloutRefs = modules.map(() => useRef(null))
  // focused ref will be (page - 1) since there is a value prop on top of the modules
  //
  const focusedModule = page > 0 && modules[Math.min(page - 1, refs.length - 1)]
  const focusedModuleRef = page > 0 && refs[Math.min(page - 1, refs.length - 1)]

  useScrollPosition(
    ({ currPos }) => {
      const index = _.findIndex(calloutRefs, moduleRef => {
        if (!moduleRef.current) {
          return true
        }
        return (
          moduleRef.current.offsetTop >
          currPos.y * -1 + window.innerHeight * 0.5
        )
      })
      const newPage = index === -1 ? modules.length : index
      if (newPage !== page) {
        setPage(newPage)
      }
    },
    [page, setPage, calloutRefs]
  )

  // modulesTranslateY is the amount that we'll shift the modules to ensure they are rendered
  // properly vertically. in the center on desktop and at the top on mobile
  let modulesTranslateY = 0
  // to determine how much to move the modules we need the element to render
  if (
    focusedModuleRef &&
    focusedModuleRef.current &&
    typeof window !== "undefined"
  ) {
    modulesTranslateY =
      -focusedModuleRef.current.offsetTop +
      (window.innerHeight - focusedModuleRef.current.offsetHeight) * 0.5 -
      styles.topOffsetLarge
  }

  const { color: colorName = "royalPurple" } = focusedModule || {}
  const { bgColor } = getColors(colorName)

  return (
    <>
      <div
        className={cx(styles.accentColor)}
        style={{
          backgroundColor: bgColor,
          // opacity: page === modules.length ? 0 : 1,
        }}
      />
      <div className={styles.callouts}>
        <ValueProp title={title} subtitle={subtitle} logos={logos} />
        {modules.map((module, index) => {
          const {
            callout,
            calloutTitle,
            quote,
            accent,
            moduleType,
            color: moduleColorName,
          } = module
          const { highlightColor, color } = getColors(moduleColorName)
          return (
            <div
              ref={calloutRefs[index]}
              className={cx(
                "section",
                "fp-auto-height-responsive",
                styles.calloutContainer
              )}
            >
              {/* modules are not visible here on desktop */}
              <HeroModule
                module={module}
                className={styles.inlineModule}
                focused={
                  index === page - 1 ||
                  page === 0 ||
                  page === modules.length + 1
                }
                onUnfocusedClick={() => setPage(index + 1)}
              />
              <Callout
                color={color}
                highlightColor={highlightColor}
                quote={quote}
                title={calloutTitle}
                text={callout}
                accent={accent}
                focused={
                  index === page - 1 ||
                  page === 0 ||
                  page === modules.length + 1
                }
                moduleType={moduleType}
              />
            </div>
          )
        })}

        <Footer
          guideLinks={[
            {
              href: "https://blog.resource.io",
              title: "Blog",
            },
            {
              title: "Security",
              href: "/guide-security",
            },
            {
              title: "Contact Us",
              href: "/contact",
            },
          ]}
          className={cx("section", "fp-auto-height", styles.footer)}
          guideCtaTitle="The Candidate Experience Company"
          guideCtaSubtitle="We're on a mission to wow candidates."
          guideNotifySubtitle="Want to read about the world's best candidate experiences?"
          guideShowSubCTA
          guideNotifyCTA="Subscribe"
        />
      </div>
      {/* modules are not visible here on mobile */}
      <div
        className={styles.modules}
        style={{
          ...(focusedModuleRef
            ? {
                transform: `translateY(${modulesTranslateY}px)`,
              }
            : {}),
        }}
      >
        <ValueProp title={title} subtitle={subtitle} />
        <Paper className={styles.moduleContainer}>
          {modules.map((module, index) => {
            return (
              <HeroModule
                domRef={refs[index]}
                module={module}
                focused={
                  index === page - 1 ||
                  page === 0 ||
                  page === modules.length + 1
                }
                onUnfocusedClick={() => setPage(index + 1)}
              />
            )
          })}
        </Paper>
      </div>
    </>
  )
}

export default ({ ...props }) => {
  const [page, setPage] = useState(0)
  return (
    <GuidesHomepage
      page={page}
      setPage={setPage}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
