import React  from 'react';

import YouTube from './YouTube/YouTube';

import styles from './Media.module.scss';

const Media = ({
  videoId='1EevEHh58B0',
}) => {
  return (
    <div className={styles.mediaContainer}>
      <YouTube videoId={videoId} />
    </div>
)
};

export default Media;
