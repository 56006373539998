import React from 'react';
import { Typography } from '@material-ui/core';

import styles from './DescriptionDivider.module.scss';

const DescriptionDivider = ({ text })=> (
  <div className={styles.descriptionWrapper}>
    { text && <Typography className={styles.description}>{text}</Typography>}
  </div>
);


export default DescriptionDivider;
