import React, { useState} from 'react';
import {
  Stepper, Step, StepButton, Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import styles from './StageTracker.module.scss';

const stages  =[
  {id: 0, title: 'Phone Screen'},
  {id: 1, title: 'Manager Screen'},
  {id: 2, title: 'Final Round'},
  {id: 3, title: 'Offer'},
];

const StageTracker = ({
  currentStageIndex = 2,
  companyLogoUrl='https://vignette.wikia.nocookie.net/looneytunes/images/5/56/Comp_2.jpg/revision/latest/scale-to-width-down/340?cb=20121102161419',
  companyName='ACME'
}) => {
  const [selectedStageIndex, setSelectedStageIndex] = useState(currentStageIndex);
  return (
    <div
      className={styles.container}
    >
      <img
        src={companyLogoUrl || ''}
        alt={`${companyName} Logo`}
        className={styles.logo}
      />
      <Stepper
        activeStep={selectedStageIndex}
        className={styles.stepper}
        nonLinear
      >
        {stages.map(
        ({
          id,
          title,
        }, index) => (
          <Tooltip
            key={id}
            title="Unlock this stage when you pass your previous interview!"
            disableFocusListener={index <= currentStageIndex}
            disableHoverListener={index <= currentStageIndex}
            disableTouchListener={index <= currentStageIndex}
          >
            <Step
              key={id}
              completed={index <= currentStageIndex}
              disabled={index > currentStageIndex}
            >
              <StepButton
                onClick={()=> {
                  setSelectedStageIndex(index);
                }}
              >
                {index > currentStageIndex && (
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{
                      marginRight: '5px',
                      height: '1em',
                    }}
                    size="xs"
                  />
                )}
                {title}
              </StepButton>
            </Step>
          </Tooltip>
        ),
      )}
      </Stepper>
    </div>
  );
};

export default StageTracker;
