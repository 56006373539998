const options = [
  {
    value: 0,
    description: 'It was bad',
    emoji: '😠',
  },
  {
    value: 1,
    description: 'It could have been better',
    emoji: '🙁',
  },
  {
    value: 2,
    description: 'It was alright',
    emoji: '😐',
  },
  {
    value: 3,
    description: 'It was good',
    emoji: '🙂',
  },
  {
    value: 4,
    description: 'It was great',
    emoji: '😃',
  },
];

export default options;
