import React, {
  useState,
} from 'react';
import cx from 'classnames';
import {
  Typography, Button, TextField,
} from '@material-ui/core';
import CategoryMultiSelection from './CategoryMultiSelection';
import categories from './categories';
import feedbackStyles from '../../feedback.module.scss';
import styles from './detailed-feedback.module.scss';


const DetailedFeedback= ({
  title,
  onSubmitDetailedFeedback,
}) => {
  const [categoriesSelected, selectCategories] = useState([]);
  const [shouldShowFreeformFeedback, setShouldShowFreeformFeedback] = useState(false);
  const [freeformFeedback, setFreeformFeedback] = useState('');
  const addCategory = (category)=> {
    selectCategories([...categoriesSelected, category]);
    setShouldShowFreeformFeedback(true);
  };
  const removeCategory = (category)=> selectCategories(
    categoriesSelected.filter((c) => c !== category),
  );
  return (
    <>
      <Typography className={feedbackStyles.header} variant="h4">{title}</Typography>
      <CategoryMultiSelection
        categories={categories}
        addCategory={addCategory}
        removeCategory={removeCategory}
        categoriesSelected={categoriesSelected}
      />
      <div
        className={cx(styles.freeforContainer)}

      >
        {shouldShowFreeformFeedback && (
        <TextField
          variant="outlined"
          rowsMax={3}
          placeholder="Anything else would you like to share?"
          color="primary"
          multiline
          value={freeformFeedback}
          fullWidth
          onChange={(event)=> {
            setFreeformFeedback(event.target && event.target.value);
          }}
        />
        )}
      </div>
      <div
        className={cx(styles.buttonContainer)}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={()=> {
            onSubmitDetailedFeedback(freeformFeedback, categoriesSelected);
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default DetailedFeedback;
