import React from 'react';
import CategoryChip from './CategoryChip';

const CategoryMultiSelection= ({
  categories, categoriesSelected, addCategory, removeCategory,
}) => (
  <div>
    {categories.map((category) => (
      <CategoryChip
        category={category}
        key={category.id}
        isSelected={categoriesSelected.includes(category.id)}
        setSelected={(selected)=> {
          if (selected) {
            addCategory(category.id);
          } else {
            removeCategory(category.id);
          }
        }}
      />
    ))}
  </div>
);

export default CategoryMultiSelection;
