import React, { useState } from 'react';
import cx from 'classnames';
import {
  Paper, Typography,
} from '@material-ui/core';
import ConferenceCard from './ConferenceCard/ConferenceCard';

import styles from './Location.module.scss';

const getStaticMapsUrl = (coordinates) => {
  const STATIC_MAPS_KEY = 'AIzaSyAKMpmgv_hxR-9wY6w2PIH-7RDG5LAO_oQ';
  return 'https://maps.googleapis.com/maps/api/staticmap?markers=color:red'
    + `%7C${coordinates}&center=${coordinates}`
    + `&zoom=16&size=1600x300&maptype=roadmap&key=${STATIC_MAPS_KEY}`;
};

const ConferenceLinkCard= ({
  instructionOptions=[{
    link: true,
    text: 'https://www.zoom.us/j/123456789',
  }],
}) => {
  const [instructionsIndex, setInstructionsIndex] = useState(0);
  const { link, text } = instructionOptions[instructionsIndex];
  setTimeout(() => {
    setInstructionsIndex((instructionsIndex + 1) % instructionOptions.length);
  }, 10000);

  return (
    <ConferenceCard link={link} text={text} />
  );
};


const Location = ({
  address = '383 Tehama St, San Francisco, CA 94103, USA',
  coordinates = '37.781433,-122.4038643',
}) => {
  return (
    <>
      <ConferenceLinkCard />
      <Paper className={cx(styles.addressContainer, styles.location)}>
        <Typography variant="h4">
        Address
        </Typography>
        <Typography variant="body1">
          {address}
        </Typography>
        <div className={styles.links}>
          <a
            className={styles.link}
            color="inherit"
            href={`https://maps.google.com/?q=${address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
          Get directions
          </a>
        </div>
        {address && (
        <a
          href={`https://maps.google.com/?q=${address}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={styles.map}
            alt="Google Maps Location"
            src={getStaticMapsUrl(coordinates)}
          />
        </a>
      )}
      </Paper>
    </>
  );
};

export default Location;
