import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import InfoCard from './InfoCard/InfoCard';

import variables from '../../../styles/modules/_variables.scss';
import styles from './Logistics.module.scss';

const Logistics = () => {
  const showMoreDetail = typeof(window) === 'undefined' || window.innerWidth > Number(variables.md.split('px')[0]);
  const logistics = [
    {id: "b94ff8bc-dab1-4e1b-90b7-639206648d54", icon: "🎧", title: "Find a Quiet Spot", description: "Make sure the background noise isn’t too high so we can focus on our conversation - not your neighbor's coffee date"},
    {id: "b839307a-4fc9-4e6e-b671-e275958e4d49", icon: "🎥", title: "Video Prep", description: "Dress is casual, but be prepared to have your video on. We recommend changing out of your PJs!"},
    {id: "2415cc30-6a65-43d2-b962-ec9e6cdf7267", icon: "📶", title: "Wifi Connectivity", description: "Make sure you have a stable connection so we can focus on our conversation!"},
    {id: "b94ff8bc-dab1-4e1b-90b7-639206648d54", icon: "🤔", title: "Prepare Your Questions", description: "We want you to interview us as well!"},
    ...showMoreDetail ? [
      {id: "3de7dd5f-f999-40dd-9240-842d6164478b", icon: "🚻", title: "Gender Neutral Bathrooms", description: "We have bathroom accommodations for everyone 😊"},
      {id: "c022d684-eb98-4400-9a9a-4bd2e34f1c1c", icon: "🐶", title: "Dogs In The Office", description: "We’ve got furry friends around, so let us know if you have an allergy."},
    ] : [],
  ];

  return (
    <div
      className={styles.container}
    >
      {showMoreDetail && (
      <Typography className={styles.header} variant="h4">
    Logistics
      </Typography>
)}
      <div className={styles.cardsContainer}>
        {logistics.map((logistic) => <InfoCard {...logistic} />)}
      </div>
    </div>
  );
};

export default Logistics;


