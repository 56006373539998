import React from 'react';

import styles from './emoji-selector.module.scss';

const EmojiSelector= ({
  options,
  onHoverOption,
  onSelectOption,
}) => (
  <div
    className={styles.container}
    onMouseLeave={() => { onHoverOption(null); }}
  >
    {options.map((option, index) => {
      const { emoji, value } = option;
      return (
        <span
          tabIndex={index}
          className={styles.emoji}
          role="button"
          key={value}
          onMouseOver={() => { onHoverOption(option); }}
          onFocus={() => { onHoverOption(option); }}
          onClick={() => { onSelectOption(option); }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSelectOption(option);
            }
          }}
        >
          {emoji}
        </span>
      );
    })}
  </div>
);
export default EmojiSelector;
